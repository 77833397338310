import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import IconTray from './components/IconTray';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import ContactContent from './components/ContactContent';
import AboutContent from './components/AboutContent';
import Footer from './components/Footer';
import './App.css';

function App() {
  const [activeContent, setActiveContent] = useState('home');
  const [selectedFile, setSelectedFile] = useState('/archive/202411.html');
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [iconTrayVisible, setIconTrayVisible] = useState(false);

  const toggleSidebar = (visible) => {
    setSidebarVisible(visible);
  };

  const toggleIconTray = (visible) => {
    setIconTrayVisible(visible);
  };
  // const toggleIconTray = () => {
  //   setIconTrayVisible(!iconTrayVisible);
  // };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIconTrayVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <Header toggleIconTray={toggleIconTray} iconTrayVisible={iconTrayVisible} />
      <Sidebar
        setActiveContent={setActiveContent}
        setSelectedFile={setSelectedFile}
        sidebarVisible={sidebarVisible}
        toggleSidebar={toggleSidebar} // サイドバーのトグル関数を渡す
      />
      <IconTray
        setActiveContent={setActiveContent}
        toggleSidebar={toggleSidebar}
        className={sidebarVisible ? 'hidden' : 'visible'} // クラス名を条件に応じて設定
        visible={iconTrayVisible}
      />
      {/* {!sidebarVisible && (
        <IconTray setActiveContent={setActiveContent} toggleSidebar={toggleSidebar} /> // アイコントレーコンポーネントを追加
      )} */}
      {/* <IconTray setActiveContent={setActiveContent} /> */}
      <main>
        <div className="container">
          {activeContent === 'home' && <MainContent selectedFile={selectedFile} />}
          {activeContent === 'contact' && <ContactContent />}
          {activeContent === 'about' && <AboutContent />}
        </div>
      </main>
      <Footer />
      <div id="copyrightModal" className="copyright-modal">
        <div className="copyright-modal-content">
          <span className="close-copyright-button">&times;</span>
          <p>© 2024 by 阿部大登 (Hiroto Abe). All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default App;
